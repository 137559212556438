import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../../hooks/useSiteMetadata";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Seo = ({
	meta,
	image,
	title,
	description,
	pathSlug,
	keywords,
	location,
}) => {
	const { t } = useTranslation();
	const siteData = useSiteMetadata();

	var index = "index";
	var path1 = location.replace("/en/", "");
	var path2 = path1.replace("/fr/", "");

	var mainPath = "";

	if (path2.length > 1) {
		mainPath = path2.replace("/", "");
	} else {
		mainPath = index;
	}

	const seoTitle = t(mainPath);
	const titleText = seoTitle || siteData.title;
	const metaDescription = description || siteData.description;
	const url = pathSlug
		? `${siteData.siteUrl}${location}`
		: `${siteData.siteUrl}`;
	const metaImage = image ? `${siteData.siteUrl}/${image.src}` : null;
	const metadata = meta || {};

	return (
		<Helmet htmlAttributes={{ lang: `fr` }}>
			<title>{titleText}</title>
			<meta name="description" content={metaDescription} />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={title || siteData.title} />
			<meta name="og:description" content={metaDescription} />
			{metaImage && <meta property="og:image" content={metaImage} />}
			<meta name="twitter:description" content={metaDescription} />
			<meta name="twitter:title" content={title || siteData.title} />
			<meta name="twitter:creator" content={siteData.twitter} />
			<meta name="twitter:card" content="summary" />
			{metaImage && <meta name="twitter:image" content={metaImage} />}
		</Helmet>
	);
};

export default Seo;

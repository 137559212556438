import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
	{
		file(relativePath: { eq: "footer-map.png" }) {
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED
					formats: [AUTO, WEBP, AVIF]
				)
			}
		}
	}
`;

const F = () => {
	const { file } = useStaticQuery(query);
	const footerMap = getImage(file);
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();

	return (
		<footer className="footer-area bg-color">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<a href="/" className="logo">
								<img src={logo} alt="logo" />
							</a>
							<p>{t("Footer")}</p>
							<ul className="social-link">
								<li>
									<a
										href="https://www.facebook.com/crowlrTalent"
										className="d-block"
										target="_blank"
										rel="noreferrer"
									>
										<i className="bx bxl-facebook"></i>
									</a>
								</li>
								<li>
									<a
										href="https://twitter.com/CrowlrNetwork"
										className="d-block"
										target="_blank"
										rel="noreferrer"
									>
										<i className="bx bxl-twitter"></i>
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/crowlrapp"
										className="d-block"
										target="_blank"
										rel="noreferrer"
									>
										<i className="bx bxl-instagram"></i>
									</a>
								</li>
								<li>
									<a
										href="https://www.linkedin.com/company/crowlr"
										className="d-block"
										target="_blank"
										rel="noreferrer"
									>
										<i className="bx bxl-linkedin"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h3>Explorer</h3>

							<ul className="footer-links-list">
								<li>
									<Link to="/hr-recruitment-solution">
										{t("FRecrutements")}
									</Link>
								</li>
								<li>
									<Link to="/talent-management-system">
										{t("FCompanyBranding")}
									</Link>
								</li>
								<li>
									<Link to="/recruiting-system">
										{t("FExpérienceCandidat")}
									</Link>
								</li>
								<li>
									<Link to="/employee-profile-branding-system">
										{t("FPersonalBranding")}
									</Link>
								</li>
								<li>
									<Link to="/talent-acquisition-strategy">
										{t("FTalentAquisition")}
									</Link>
								</li>
								<li>
									<Link to="/cooptation-recruitment-solutions">
										{t("FRecrutementparCooptation")}
									</Link>
								</li>
								<li>
									<Link to="/interactive-job-candidacy-and-employability">
										{t("FAideàladécision")}
									</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h3>Ressources</h3>

							<ul className="footer-links-list">
								<li>
									<Link to="/about-us">{t("FApropos")}</Link>
								</li>
								<li>
									<Link to="/blog">{t("FBlog")}</Link>
								</li>
								<li>
									<Link to="/contact-direct">
										{t("FContact")}
									</Link>
								</li>
								<li>
									<Link to="/ecosysteme">
										{t("FÉcosystème")}
									</Link>
								</li>
								<li>
									<a
										href="https://admin.crowlr.com/careers/jobs"
										target="_blank"
										rel="noreferrer"
									>
										{t("FJobBoard")}
									</a>
								</li>
								<li>


									<a
										href="https://admin.crowlr.com/careers/talentpool"
										target="_blank"
										rel="noreferrer"
									>
										{t("FTalentPoolsViviers")}
									</a>



								</li>
								<li>
									<Link to="/faq">{t("FFAQ")}</Link>
								</li>
							</ul>
						</div>
					</div>

					<div className="col-lg-3 col-sm-6">
						<div className="single-footer-widget">
							<h3>{t("FAdresse")}</h3>

							<ul className="footer-contact-info">
								<li>
									<i className="bx bx-map"></i>
									Chemin de Villardin, 1004 Lausanne
									<br />
									Vaud, Switzerland
								</li>
								<li>
									<i className="bx bx-phone-call"></i>
									<a href="tel:+33624442696">
										+33 (6) 2444 2696
									</a>
								</li>
								<li>
									<i className="bx bx-envelope"></i>
									<a href="mailto:contact@crowlr.com">
										contact@crowlr.com
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="footer-bottom-area">
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-6">
							<p>Copyright &copy;{currentYear} </p>
							<p>
								<strong>PROD LLC</strong>
							</p>
							<p>
								All rights reserved
								<a
									target="_blank"
									href="https://compr.fr/"
									rel="noreferrer"
								>
									<p>
										CSS/ CROWLR <span>&#174;</span>
									</p>
								</a>
							</p>
						</div>

						<div className="col-lg-6 col-md-6">
							<ul>
								<li>
									<Link to="/privacy-policy">
										{t("FPolitiquedeConfidentialité")}
									</Link>
								</li>
								<li>
									<Link to="/terms-of-service">
										{t("FConditionsdUtilisation")}
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-map">
				<GatsbyImage image={footerMap} alt="map" />
			</div>
		</footer>
	);
};

export default F;
